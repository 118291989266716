<template>
  <el-card shadow="never" class="m-8">
    <Loader :loading="$apollo.loading || loading">
      <div v-show="status !== 'connected'">
        <h1>Intégration Facebook Leads</h1>
        <div class="mb-6">
          Commencez par vous connecter avec votre compte Facebook.
          <br />
          Une fois configuré, vos données Facebook Leads seront importées dans
          Ignition.
        </div>
        <VFBLogin
          app-id="128290771071688"
          :login-options="fbSignInParams"
          @login="onSignInSuccess"
          use-alt-logo
          :style="{ cursor: 'pointer' }"
        >
          <template #login>
            <span class="text-2xl">Connexion avec Facebook</span>
          </template>
          <template #working>
            <span class="text-2xl">Chargement</span>
          </template>
          <template #logout>
            <span class="text-2xl">Déconnexion d'avec Facebook</span>
          </template>
        </VFBLogin>
      </div>
      <el-alert
        v-show="status === 'connected'"
        :closable="false"
        type="success"
        show-icon
      >
        <template #title>
          <span class="text-2xl text-bold poppins">
            Votre compte Facebook est correctement configuré
          </span>
        </template>
        <span class="text-2xl">
          Vos données Facebook Leads seront importées dans Ignition
          quotidiennement.
        </span>
      </el-alert>
    </Loader>
  </el-card>
</template>

<script>
import { VFBLogin } from "vue-facebook-login-component";
import Loader from "@/components/Loader.vue";
import facebookConfigurationQuery from "./queries/facebookConfiguration.gql";
import setFacebookConfigurationQuery from "./queries/setFacebookConfiguration.gql";

export default {
  components: { VFBLogin, Loader },
  data: () => ({ status: "unknown", loading: false }),
  apollo: {
    status: {
      query: facebookConfigurationQuery,
      update: ({ userMe: { token } }) => (token ? "connected" : "unknown"),
      error({ graphQLErrors, networkError }) {
        this.$notify.error({
          title:
            "Erreur lors de la récupération de votre configuration Facebook",
          message: JSON.stringify(networkError || graphQLErrors)
        });
      }
    }
  },
  computed: {
    fbSignInParams: () => ({
      scope:
        "email,manage_pages,ads_management,leads_retrieval,public_profile,pages_show_list",
      enable_profile_selector: true
    })
  },
  methods: {
    onSignInSuccess({ authResponse, status }) {
      this.status = status;

      if (!authResponse)
        return this.$notify.error({
          title: "Echec de la connexion",
          message: "La connexion à Facebook a échouée."
        });

      this.loading = true;
      const { accessToken } = authResponse;
      this.$apollo
        .mutate({
          mutation: setFacebookConfigurationQuery,
          variables: { token: accessToken }
        })
        .then(() =>
          this.$notify.success({
            title: "Connexion réussi",
            message: "Votre compte Facebook est correctement configuré."
          })
        )
        .catch(({ graphQLErrors, networkError }) =>
          this.$notify.error({
            title: "Erreur lors de l'enregistrement de votre compte Facebook",
            message: JSON.stringify(networkError || graphQLErrors)
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
