<template>
  <div>
    <h1 v-if="loading" style="text-align: center">
      <fa icon="spinner" spin />
    </h1>
    <slot v-else />
  </div>
</template>

<script>
export default { props: ["loading"] };
</script>

<style scoped></style>
